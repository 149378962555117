import { graphql } from "gatsby"
import { gql } from "@apollo/client"

export const SITE_SETTINGS = graphql`
  fragment SiteSettings on SanitySiteSettings {
    title
    navMenu {
      ...NavMenu
    }
    footerNavMenu {
      ...NavMenu
    }
    socialLinks {
      title
      icon {
        asset {
          url
        }
      }
      link
    }
    openGraph {
      title
      description
    }
    headComponents
  }
`

export const NavMenu = graphql`
  fragment NavMenu on SanityNavigationMenu {
    title
    items {
      title
      kind
      link
      route
      type
      landingPageRoute {
        ... on SanityRoute {
          id
          _type
          slug {
            current
          }
        }
      }
    }
  }
`

export const PostInfo = graphql`
  fragment PageInfo on SanityPage {
    id
    _rawContent(resolveReferences: { maxDepth: 20 })
    title
  }
`
export const SanityImage = graphql`
  fragment SanityImage on SanityMainImage {
    alt
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
      metadata {
        lqip
        dimensions {
          aspectRatio
          width
          height
        }
      }
    }
  }
`

export const CHECKOUT_FIELDS = gql`
  fragment checkoutFields on Checkout {
    id
    email
    webUrl
    orderStatusUrl
    lineItems(first: 10) {
      edges {
        node {
          id
          quantity
          title
          variant {
            product {
              handle
              id
              availableForSale
            }
            id
            sku
            title
            price
            image {
              originalSrc
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    shippingAddress {
      formatted
      firstName
      lastName
      address1
      province
      country
      zip
    }
  }
`
export const CUSTOMER_FIELDS = gql`
  fragment customerFields on Customer {
    id
    email
    firstName
    lastName
    phone
    lastIncompleteCheckout {
      ...checkoutFields
    }
  }
`
