const theme = {
  fontFamily: {
    primary:
      '"lineto-circular", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    secondary:
      '"JeanLuc", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },

  grid: {
    colCount: 12,
  },
  rounded: {
    xs: "3px",
    sm: "5px",
    default: "10px",
    lg: "10px",
    circle: "1000rem",
  },
  shadows: {
    "1": "20px 20px 40px rgba(255, 211, 95, 0.2), -8px -8px 16px #FFFFFF",
    "2": "6px 6px 12px rgba(255, 211, 95, 0.3), -8px -8px 12px #FFFFFF",
    "3": "1px 1px 2px rgba(255, 211, 95, 0.4), -1px -1px 2px #FFFFFF;",
    "4": "20px 20px 40px rgba(255, 211, 95, 0.2)",
    "5": "0px 6px 12px rgba(179, 217, 213, 0.5)",
    "6": "0px 4px 8px #B3D9D5",
    "7": "-8px -8px 16px #FFFFFF, 6px 6px 12px rgba(255, 211, 95, 0.4);",
  },
  textSize: {
    size: {
      caption: "8px",
      tiny: "10px",
      paragraph: "12px",
      paragraph1: "12px",
      body: "14px",
      header: "16px",
      header1: "20px",
      title1: "24px",
      display1: "32px",
      display2: "50px",
      display3: "80px",
      display4: "120px",
      button: "24px",
    },
    height: {
      caption: "12px",
      tiny: "15px",
      paragraph: "18px",
      paragraph1: "12px",
      body: "22px",
      header: "24px",
      header1: "30px",
      title1: "36px",
      display1: "48px",
      display2: "50px",
      display3: "80px",
      display4: "140px",
      button: "24px",
    },
  },

  colors: {
    bg2: "#EAF1F9",
    blue: "#283377",
    yellow: "#FFFCF0",
    pink: "#FDEEFF",
    lightPink: "#FAEFFF",
    purple: "#FFD1FF",
    green: "#B3D9D5",
    yellowPink: "#FFE3E1",
    yellowGreen: "#f9fce8",
    navy: "#6C97C4",
    gray: "#EEE7F1",
    borderColor: "#f8d4e4",
    error: "#E74F3C",
    focus: "#B3D9D5",
    success: "#D0DF00",
    lightBlue: "#B3D9D5",
    oilGreen: "#D0DF00",
    transparent: "rgba(255,255,255,0)",
    gradient:
      "linear-gradient(180deg, #FDEEFF 0%, #FFFCE1 47.92%, #DFF9FF 100%)",
    gradient2: "linear-gradient(180deg, #FDEEFF 0%, #FFFCE1 100%)",
    gradient3:
      "linear-gradient(180deg, #FDEEFF 0%, #EDF2FF 45.31%, #F8EEFF 100%)",
  },
}
export default theme
