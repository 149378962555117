import React from "react"
import { Div, Image, Row, Col, Text, Anchor } from "atomize"
import Wave from "react-wavify"
import { Link } from "gatsby"

import CTALink from "../CTALink"
import FooterForm from "./FooterForm"
import WebsiteContainer from "../atoms/WebsiteContainer"

export default function Footer({ footerMenuItems, socialLinks }) {
  const footerLinks = footerMenuItems.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue.items)
  }, [])

  return (
    <>
      {/* Desktop wave */}
      <Div d={{ xs: "none", md: "block" }} m={{ b: "-8px" }}>
        <Wave
          fill="#fdeeff"
          filter="url(#waveFilterFooter)"
          paused={false}
          options={{
            height: 84,
            points: 8,
            amplitude: 40,
            speed: 0.25,
          }}
        >
          <defs>
            <filter
              id="waveFilterFooter"
              x="-100%"
              y="-100%"
              width="200%"
              height="200%"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feOffset result="offOut" in="SourceGraphic" dx="0" dy="-10" />
              <feColorMatrix
                result="matrixOut"
                in="offOut"
                type="matrix"
                values="0.2 0 0 0 1 0 0.2 0 0 1 0 0 0.2 0 1 0 0 0 1 0"
              />
              <feGaussianBlur
                result="blurOut"
                in="matrixOut"
                stdDeviation="16"
              />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
          </defs>
        </Wave>
      </Div>

      {/* Mobile Wave */}
      <Div d={{ xs: "block", md: "none" }} m={{ b: "-8px" }}>
        <Wave
          fill="#fdeeff"
          filter="url(#waveFilterFooterMobile)"
          paused={false}
          options={{
            height: 84,
            points: 3,
            amplitude: 40,
            speed: 0.25,
          }}
        >
          <defs>
            <filter
              id="waveFilterFooterMobile"
              x="-100%"
              y="-100%"
              width="200%"
              height="200%"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feOffset result="offOut" in="SourceGraphic" dx="0" dy="-10" />
              <feColorMatrix
                result="matrixOut"
                in="offOut"
                type="matrix"
                values="0.2 0 0 0 1 0 0.2 0 0 1 0 0 0.2 0 1 0 0 0 1 0"
              />
              <feGaussianBlur
                result="blurOut"
                in="matrixOut"
                stdDeviation="16"
              />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
          </defs>
        </Wave>
      </Div>

      {/* Footer */}
      <Div bg="pink" pos="relative" className="footer">
        <WebsiteContainer>
          <Div
            p={{ y: { xs: "3rem", lg: "6.25rem" } }}
            // m={{ t: { xs: "6.25rem", lg: "9.375rem" } }}
          >
            <Row>
              <Col size={{ xs: "12", lg: "6" }}>
                <Div p={{ lg: "0 3.5rem 0 1.5rem" }}>
                  <FooterForm />
                </Div>
              </Col>
              <Col size={{ xs: "12", lg: "6" }}>
                <Div p={{ lg: "0 2.25rem 0 5rem" }}>
                  {/* Desktop Links */}

                  <Div d={{ xs: "none", lg: "block" }}>
                    <Row>
                      {footerMenuItems.map((footerMenuItem, id) => {
                        return (
                          <Col
                            size={{ xs: "6", lg: "4" }}
                            key={`footer-menu-${id}`}
                          >
                            <Div>
                              <Text
                                d={{ xs: "none", lg: "block" }}
                                fontFamily="secondary"
                                m={{ b: "1rem" }}
                                textWeight="500"
                                textSize="header"
                                style={{ lineHeight: "16px" }}
                              >
                                {footerMenuItem.title}
                              </Text>
                              {footerMenuItem.items.map((item, id) => {
                                return (
                                  <Div
                                    m={{ b: "1rem" }}
                                    key={`footer-menu-item-${id}`}
                                  >
                                    <CTALink {...item} />
                                  </Div>
                                )
                              })}
                            </Div>
                          </Col>
                        )
                      })}
                    </Row>
                  </Div>

                  {/* Mobile Links */}

                  <Div d={{ xs: "block", lg: "none" }} m={{ t: "2.5rem" }}>
                    <Row>
                      {footerLinks.map((link, id) => {
                        return (
                          <Col size={{ xs: "6" }} key={`mobile-links-${id}`}>
                            <Div m={{ b: "1rem" }} textAlign="center">
                              <CTALink {...link} />
                            </Div>
                          </Col>
                        )
                      })}
                    </Row>
                  </Div>
                  <Div
                    m={{ t: "2rem" }}
                    d="flex"
                    justify={{ xs: "center", lg: "left" }}
                  >
                    {socialLinks.map((socialLink, index, id) => {
                      return (
                        <Anchor
                          href={socialLink.link}
                          key={`social-link-${index}`}
                        >
                          <Div
                            bg="lightPink"
                            shadow="2"
                            hoverShadow="3"
                            transition
                            rounded="sm"
                            m={{
                              r:
                                index === socialLinks.length - 1
                                  ? "0"
                                  : "1.25rem",
                            }}
                            h="2.5rem"
                            w="2.5rem"
                            d="flex"
                            align="center"
                            justify="center"
                          >
                            <Image
                              src={socialLink.icon.asset.url}
                              h="1rem"
                              w="1rem"
                            />
                          </Div>
                        </Anchor>
                      )
                    })}
                  </Div>
                  <Div
                    m={{ t: "3rem" }}
                    d="flex"
                    textSize="tiny"
                    justify={{ xs: "center", lg: "left" }}
                    flexWrap={{ xs: "wrap", lg: "nowrap" }}
                  >
                    <Image
                      src="/np-icon.png"
                      h="2.5rem"
                      w="2.5rem"
                      m={{ r: { xs: "0", lg: "1.25rem" } }}
                    />
                    <Div d="flex" flexDir="column">
                      <Div
                        d="flex"
                        align="center"
                        m={{ t: { xs: "0.625rem", lg: "0" } }}
                        order={{ xs: "2", lg: "1" }}
                        justify={{ xs: "center", lg: "left" }}
                      >
                        <Link to="/legal/privacy-policy">
                          <Text
                            textSize="tiny"
                            textColor="blue"
                            textWeight="bold"
                            textDecor="underline"
                            hoverTextColor="navy"
                          >
                            Privacy Policy
                          </Text>
                        </Link>
                        <Div
                          h="4px"
                          w="4px"
                          bg="blue"
                          rounded="circle"
                          m={{ x: "0.625rem" }}
                        ></Div>
                        <Link to="/legal/terms">
                          <Anchor
                            textSize="tiny"
                            textColor="blue"
                            textWeight="bold"
                            textDecor="underline"
                            hoverTextColor="navy"
                          >
                            Terms & Conditions
                          </Anchor>
                        </Link>
                      </Div>
                      <Div
                        d="flex"
                        m={{ t: "0.625rem" }}
                        order={{ xs: "3", lg: "2" }}
                        textAlign={{ xs: "center", lg: "left" }}
                      >
                        These statements have not been evaluated by the Food and
                        Drug Administration. This product is not intended to
                        diagnose, treat, cure, or prevent any disease.
                      </Div>
                      <Div
                        d="flex"
                        m={{ t: "0.625rem" }}
                        order={{ xs: "1", lg: "3" }}
                        justify={{ xs: "center", lg: "left" }}
                      >
                        © 2020 Blemish Inc. All Rights Reserved.
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Col>
            </Row>
          </Div>
        </WebsiteContainer>
      </Div>
    </>
  )
}
