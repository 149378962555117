import { get } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Div, Text, Image, Icon } from "atomize"
import { useMutation, useLazyQuery } from "@apollo/client"
import ls from "local-storage"

import SideDrawer from "../common/atoms/SideDrawer"
import BlueButton from "../common/molecules/BlueButton"

import { GET_PRODUCTS_BY_IDS } from "../../graphql/queries"

import { FETCH_CUSTOMER, TOGGLE_RECHARGE_CART } from "../../graphql/queries"
import {
  removeItemsFromRechargeCheckout,
  updateItemsInRechargeCheckout,
} from "../../services/cartService"

const RechargeCartModal = ({
  cart,
  onClose,
  toggleCart,
  email,
  rechargeCart,
  checkoutCreate,
  replaceCheckoutLineItems,
  updateRechargeCheckout,
  removeCheckoutLineItems,
  setLoading,
}) => {
  var line_items = get(rechargeCart, "line_items", []);
  var productss = ls("productss");

  for(var i=0; i<line_items.length; i++){
    for(var k=0; k<productss.length; k++){
      if(productss[k].sanityId == line_items[i].variant_id){
        line_items[i].['handle'] =productss[k].productHandle+'/'+line_items[i].variant_title.toLowerCase();
        }else if(productss[k].sanityId == line_items[i].product_id){
          if(productss[k].slug){
            line_items[i].['handle'] =productss[k].slug.current;
          }
        }
    }
  }
  // line_items.map((item, index) => {

  // })
  const [error, setError] = useState(null)
  const toggleRechargeCart = toggleCart
  const [getProductsByIDS, { loading: gettingProducts }] = useLazyQuery(
    GET_PRODUCTS_BY_IDS,
    {
      fetchPolicy: "network-only",
      onCompleted: result => {
        const variants = get(result, "nodes")

        const itemOutOfStock = variants.find(
          variant => variant.quantityAvailable < 1
        )

        if (itemOutOfStock) {
          setError(
            `${itemOutOfStock.product.title} - ${itemOutOfStock.title} is out of stock. Please remove the item to proceed to checkout.`
          )
        } else {
          const url = `https://checkout.rechargeapps.com/r/checkout/${rechargeCart.token}?myshopify_domain=not-pot.myshopify.com`

          window.location.href = url
        }
      },
    }
  )

  /**
   * on click - button
   *
   * @param {*} item
   */
  const onDecreaseQuantity = item => {
    setError(null)
    // if (replaceCheckoutLineItemLoading) return false

    if (item.quantity === 1) {
      removeItemsFromRechargeCheckout({
        updateRechargeCheckout,
        rechargeCart: rechargeCart,
        cart: cart,
        cartItem: item,
        checkoutCreate,
        replaceCheckoutLineItems,
        email,
        removeCheckoutLineItems,
        setLoading,
      })
    } else {
      updateItemsInRechargeCheckout({
        rechargeCheckout: rechargeCart,
        quantity: -1,
        item: item,
        updateRechargeCheckout,
        toggleRechargeCart,
      })
    }
  }

  /**
   * on click + button
   *
   * @param {*} item
   */
  const onIncreaseQuantity = item => {
    setError(null)

    updateItemsInRechargeCheckout({
      rechargeCheckout: rechargeCart,
      quantity: 1,
      item,
      updateRechargeCheckout,
      toggleRechargeCart,
    })
  }

  /**
   * on remove item
   * @param {*} item
   */
  const onRemoveItem = item => {
    setError(null)

    removeItemsFromRechargeCheckout({
      updateRechargeCheckout,
      rechargeCart: rechargeCart,
      cart: cart,
      cartItem: item,
      checkoutCreate,
      replaceCheckoutLineItems,
      email,
      removeCheckoutLineItems,
      setLoading,
    })
  }

  return (
    <>
      <Div h="100%" d="flex" flexDir="column">
        <Div
          d="flex"
          justify="space-between"
          align="center"
          m={{ b: "2.25rem" }}
          p={{ r: "1rem" }}
        >
          <Text
            textTransform="uppercase"
            textWeight="bold"
            fontFamily="secondary"
            textSize="display1"
          >
            cart
          </Text>
          <Div
            style={{ lineHeight: "0" }}
            bg="lightPink"
            shadow="2"
            hoverShadow="3"
            transition
            cursor="pointer"
            rounded="sm"
            onClick={() => onClose(false)}
          >
            <Image src="/close.svg" h="1rem" w="1rem" p="10px" />
          </Div>
        </Div>

        <Div
          flexGrow="1"
          overflow="auto"
          flexWrap="nowrap"
          p={{ b: { xs: "2.5rem", sm: "0rem" } }}
        >
          {get(rechargeCart, "line_items", []).length === 0 && (
            <Div flexGrow="1 1 0%" m={{ t: "2rem" }}>
              <Text>Bag is Empty</Text>
            </Div>
          )}
          {line_items.map((item, index) => {
            return (
              <Div key={`cart-list-item-${index}`}>
                {/* {[1, 2, 3].map(id => {
            return ( */}
                <Div
                  p={{ y: "2.5rem" }}
                  m={{ r: "1rem" }}
                  border={{ t: "1px solid" }}
                  borderColor="yellowPink"
                  d="flex"
                  //key={id}
                >
                <Link
                      to={`/products/${item.handle}`}
                      style={{ color: "inherit" }}
                      onClick={() => {
                         toggleRechargeCart({ variables: { cartDrawerVisible: false } }) 
                      }}
                    >
                  <Div
                    bgImg={get(item, "image")}
                    bgPos="center"
                    bgSize="cover"
                    rounded="10px"
                    m={{ r: "1.25rem" }}
                    h="4rem"
                    w="4rem"
                  />
                  </Link>
                  <Div flexGrow="1">
                    <Div d="flex" justify="space-between" align="center">
                      <Link
                      to={`/products/${item.handle}`}
                      style={{ color: "inherit" }}
                      onClick={() => {
                          toggleRechargeCart({ variables: { cartDrawerVisible: false } })
                      }}
                    ><Text textWeight="bold">{item.title}</Text></Link>
                    
                      <Div
                        style={{ lineHeight: "0" }}
                        bg="lightPink"
                        shadow="2"
                        cursor="pointer"
                        rounded="circle"
                        m={{ l: "20px" }}
                        onClick={() => {
                          onRemoveItem(item)
                        }}
                      >
                        <Image src="/close.svg" h="10px" w="10px" p="5px" />
                      </Div>
                    </Div>
                    <Div>
                      {item.variant_title !== "Default Title" && (
                        <Text m={{ t: "10px" }} textSize="paragraph1">
                          {item.variant_title}
                        </Text>
                      )}
                      <Text m={{ t: "5px" }} textSize="paragraph1">
                        ${(item.price * item.quantity).toFixed(2)}
                      </Text>
                      {item.charge_interval_frequency && (
                        <Text
                          textSize="paragraph1"
                          m={{ t: "5px" }}
                          textColor="gray900"
                        >
                          {`deliver every ${item.charge_interval_frequency} Days`}
                        </Text>
                      )}
                      {/* //Counter for products */}
                      <Div
                        d="flex"
                        rounded="lg"
                        m={{ t: "10px" }}
                        shadow="2"
                        maxW="4rem"
                        justify="space-between"
                      >
                        <Text
                          textWeight="bold"
                          p="2px 10px"
                          cursor="pointer"
                          onClick={() => {
                            onDecreaseQuantity(item)
                          }}
                          // opacity={replaceCheckoutLineItemLoading ? "0.3" : "1"}
                        >
                          -
                        </Text>
                        <Text textWeight="bold" p="2px 0">
                          {/* {replaceCheckoutLineItemLoading ? (
                            <Image src="/loader.svg" />
                          ) : ( */}
                          {item.quantity}
                          {/* )} */}
                        </Text>
                        <Text
                          textWeight="bold"
                          p="2px 10px"
                          cursor="pointer"
                          onClick={() => {
                            onIncreaseQuantity(item)
                          }}
                          // opacity={replaceCheckoutLineItemLoading ? "0.3" : "1"}
                        >
                          +
                        </Text>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Div>
            )
          })}
        </Div>

        {error && (
          <Div>
            <Text textColor="danger700" m={{ b: "1rem" }} transition>
              {error}
            </Text>
          </Div>
        )}

        {get(rechargeCart, "line_items", []).length > 0 && (
          <Div
            m={{ r: { sm: "1rem" } }}
            pos={{ xs: "fixed", sm: "static" }}
            left="1rem"
            right="1rem"
            bottom="2rem"
            w={{ sm: "100%" }}
          >
            <Div className="safe-margin">
              <BlueButton
                w="100%"
                text="Checkout"
                rounded="lg"
                onClick={() => {
                  const url = `https://checkout.rechargeapps.com/r/checkout/${rechargeCart.token}?myshopify_domain=not-pot.myshopify.com`
                  getProductsByIDS({
                    variables: {
                      ids: get(rechargeCart, "line_items", []).map(item =>
                        btoa(`gid://shopify/ProductVariant/${item.variant_id}`)
                      ),
                    },
                  })
                  // window.location.href = url
                }}
                disabled={gettingProducts}
                prefix={
                  <Icon
                    name="Loading"
                    pos="absolute"
                    top="50%"
                    left="8.5%"
                    transform="translateY(-50%)"
                    size="25px"
                    color="white"
                    style={{ zIndex: 1, opacity: gettingProducts ? 1 : 0 }}
                    m={{ r: "0.5rem" }}
                  />
                }
              />
            </Div>
          </Div>
        )}
      </Div>
    </>
  )
}

export default RechargeCartModal
