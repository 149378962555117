import _ from "lodash"
import React from "react"
import { Div, Anchor, Input, Text, Icon, Image } from "atomize"
import axios from "axios"
import * as yup from "yup"
import { Formik } from "formik"
import CardHeader from "../CardHeader"
import BlueButton from "../molecules/BlueButton"

export default function FooterForm() {
  let formikProps

  const initialValues = {
    phone: "",
    email: "",
  }

  const onSubmit = values => {
    const { setSubmitting, setStatus, resetForm } = formikProps
    const klaviyoPromise = axios.post(`/api/klaviyo`, {
      email: values.email,
      phone: "+1" + values.phone,
    })

    const postscriptPromise = axios.post(`/api/postscript`, {
      phone: values.phone,
    })

    Promise.all([klaviyoPromise, postscriptPromise])
      .then(responses => {
        setSubmitting(false)
        resetForm(initialValues)
        setStatus({
          successMessage:
            "Thanks! Please be on the lookout for emails and texts from the Dealer ;)",
          errorMessage: "",
        })
      })
      .catch(error => {
        setSubmitting(false)
        setStatus({
          errorMessage: "Something went wrong. Please try again later.",
          successMessage: "",
        })
      })
  }

  const validator = yup.object().shape({
    email: yup
      .string()
      .required("Please use a valid email address.")
      .min(1)
      .email("Please use a valid email address."),
    phone: yup.string().required("Please use a valid phone number.").min(10),
  })

  return (
    <Div rounded="lg" border="1px solid" borderColor="yellowPink" shadow="1">
      <CardHeader
        heading="not spam"
        image="/disk.svg"
        padding="0.625rem 0.625rem"
      />
      <Div
        bg="yellow"
        p="1.25rem"
        rounded={{ b: "lg" }}
        d="flex"
        flexDir="column"
      >
        <Text>
          Be the first to know about new launches, restocks, and more (like
          secret merch).
        </Text>
        <Formik
          initialValues={{
            phone: "",
            email: "",
          }}
          onSubmit={onSubmit}
          validationSchema={validator}
        >
          {props => {
            const {
              values,
              status,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = props
            formikProps = props
            return (
              <form onSubmit={handleSubmit}>
                <Div d="flex" m={{ t: "1.25rem" }} flexDir="column">
                  {status && status.successMessage && (
                    <Text
                      textColor="success700"
                      m={{ b: "1rem" }}
                      opacity={status.successMessage ? "1" : "0"}
                      transition
                    >
                      {status.successMessage}
                    </Text>
                  )}
                  {status && status.errorMessage && (
                    <Text
                      textColor="danger700"
                      m={{ b: "1rem" }}
                      opacity={status.errorMessage ? "1" : "0"}
                      transition
                    >
                      {status.errorMessage}
                    </Text>
                  )}
                  <Input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    placeholderTextColor="navy"
                    shadow="2"
                    textColor="blue"
                    h="2.5rem"
                    border="1px solid"
                    borderColor={
                      touched.phone
                        ? values.phone
                          ? errors.phone
                            ? "red"
                            : "oilGreen"
                          : errors.phone
                            ? "red"
                            : "transparent"
                        : "transparent"
                    }
                    p="0 2.125rem 0 2.125rem"
                    prefix={
                      <Div
                        d="flex"
                        align="center"
                        transform="translateY(-50%)"
                        pos="absolute"
                        top="50%"
                        left="0.75rem"
                      >
                        <Image src="/phone.svg" w="14px" h="14px" />
                      </Div>
                    }
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Text
                    textColor="error"
                    textSize="paragraph"
                    m={{ t: ".5rem" }}
                  >
                    {touched.phone && _.capitalize(errors.phone)}
                  </Text>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Email"
                    placeholderTextColor="navy"
                    shadow="2"
                    textColor="blue"
                    h="2.5rem"
                    border="1px solid"
                    borderColor={
                      touched.email
                        ? values.email
                          ? errors.email
                            ? "red"
                            : "oilGreen"
                          : errors.email
                            ? "red"
                            : "transparent"
                        : "transparent"
                    }
                    p="0 2.125rem"
                    prefix={
                      <Image
                        src="/email.svg"
                        w="14px"
                        h="10px"
                        pos="absolute"
                        top="50%"
                        left="0.75rem"
                        transform="translateY(-50%)"
                      />
                    }
                    m={{ t: "0.625rem" }}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Text
                    textColor="error"
                    textSize="paragraph"
                    m={{ t: ".5rem" }}
                  >
                    {touched.email && _.capitalize(errors.email)}
                  </Text>
                  <BlueButton
                    type="submit"
                    m={{ t: "0.625rem" }}
                    text={
                      isSubmitting ? (
                        <Icon name="Loading" color="white" size="16px" />
                      ) : (
                          "Sign Up"
                        )
                    }
                    padding="0.5rem 1.25rem"
                    textSize="header1"
                    h="2.5rem"
                    w="100%"
                  />
                </Div>
                <Text m={{ t: "0.625rem" }} textSize="caption">
                  By signing up, you agree to receive recurring automated text
                  messages at the phone number provided. Consent is not a
                  condition to purchase. Msg & data rates may apply. View{" "}
                  <Anchor
                    textColor="blue"
                    hoverTextColor="navy"
                    textDecor="underline"
                    href="/legal/terms"
                  >
                    Terms
                  </Anchor>{" "}
                  &{" "}
                  <Anchor
                    textColor="blue"
                    hoverTextColor="navy"
                    textDecor="underline"
                    href="/legal/privacy-policy"
                  >
                    Privacy{" "}
                  </Anchor>
                  .
                </Text>
              </form>
            )
          }}
        </Formik>
      </Div>
    </Div>
  )
}
