import React, { useEffect, useState } from "react"
import { get } from "lodash"
import { useMutation, useLazyQuery } from "@apollo/client"
import { useLocation } from "@reach/router"
import { Div, Text, Image, Icon } from "atomize"
import ls from "local-storage"
import { Link } from "gatsby"

import SideDrawer from "../common/atoms/SideDrawer"
import BlueButton from "../common/molecules/BlueButton"
import RechargeCartModal from "./RechargeCartModal"

import {
  removeItemsFromCheckout,
  replaceItemsFromCheckout,
  syncGuestCartWithCustomerCart,
  saveCheckoutInLocalStorage,
  updateRechargeCheckout,
} from "../../services/cartService"
import {
  FETCH_CUSTOMER,
  CHECKOUT_CREATE,
  CHECKOUT_LINE_ITEMS_ADD,
  CHECKOUT_LINE_ITEMS_REMOVE,
  CHECKOUT_LINE_ITEMS_REPLACE,
  UPDATE_RECHARGE_CHECKOUT,
  GET_PRODUCTS_BY_IDS,
} from "../../graphql/queries"

const CartModal = ({
  guestCart,
  rechargeCart,
  customer,
  isOpen,
  onClose,
  toggleCart,
  variant,
  productCount,
}) => {
  const location = useLocation()
  const [checkingOutShopify, setCheckingOutShopify] = useState(false)
  const cart = get(customer, "lastIncompleteCheckout") || guestCart
  
  const [loading, setLoading] = useState(false)
  const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
    onCompleted: result => {
      toggleCart({ variables: { cartDrawerVisible: true } })
      //window.history.pushState("header", "Not Pot", "/#Cart")
      saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
      //
      setTimeout(function(){
        setLoading(false)
      }, 1000)
    },
  })

  const [checkoutLineItemsAdd] = useMutation(CHECKOUT_LINE_ITEMS_ADD)
  const [updateRechargeCheckoutInCache] = useMutation(UPDATE_RECHARGE_CHECKOUT)

  const [
    removeCheckoutLineItems,
    { loading: removeCheckoutLineItemLoading, data: removeCheckoutLineData },
  ] = useMutation(CHECKOUT_LINE_ITEMS_REMOVE, {
    onCompleted: result => {
      setLoading(false);
     // ls("ShowCart", true)
     // window.location.reload()
      //refetchCheckout()
    },
  })

  const [
    replaceCheckoutLineItems,
    { loading: replaceCheckoutLineItemLoading, data: replaceCheckoutLineData },
  ] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE, {
    onCompleted: result => {
      setLoading(false);
     // ls("ShowCart", true)
     // window.location.reload()
      // refetchCheckout()
    },
  })

  /**
   * on click - button
   *
   * @param {*} item
   */
  const onDecreaseQuantity = item => {
    if (replaceCheckoutLineItemLoading) return false

    if (item.quantity === 1) {
      removeItemsFromCheckout({
        removeCheckoutLineItems,
        checkout: cart,
        cartItem: item,
      })
    } else {
      replaceItemsFromCheckout({
        replaceCheckoutLineItems,
        checkout: cart,
        quantity: item.quantity - 1,
        cartItem: item,
      })
    }
  }

  /**
   * on click + button
   *
   * @param {*} item
   */
  const onIncreaseQuantity = item => {
    if (replaceCheckoutLineItemLoading) return false

    replaceItemsFromCheckout({
      replaceCheckoutLineItems,
      checkout: cart,
      quantity: item.quantity + 1,
      cartItem: item,
    })
  }

  /**
   * on remove item
   * @param {*} item
   */
  const onRemoveItem = item => {
    removeItemsFromCheckout({
      removeCheckoutLineItems,
      checkout: cart,
      cartItem: item,
    })
  }
  
  /**
   * sync guest cart
   */
  useEffect(() => {
    if (
      get(guestCart, "id") &&
      get(customer, "lastIncompleteCheckout.id") &&
      get(guestCart, "id") !== get(customer, "lastIncompleteCheckout.id")
    ) {
      syncGuestCartWithCustomerCart({
        guestCheckout: guestCart,
        customer,
        checkoutLineItemsAdd,
        checkoutCreate,
      })
    }

    return () => setCheckingOutShopify(false)
  }, [guestCart?.id, customer])

  if (rechargeCart) {
    return (
      <SideDrawer
        isOpen={isOpen}
        onClose={onClose}
        style={{ overscrollBehavior: "contain" }}
      >
        <RechargeCartModal
          cart={cart}
          rechargeCart={rechargeCart}
          onClose={onClose}
          email={customer?.email}
          toggleCart={toggleCart}
          checkoutCreate={checkoutCreate}
          replaceCheckoutLineItems={replaceCheckoutLineItems}
          updateRechargeCheckout={updateRechargeCheckoutInCache}
          removeCheckoutLineItems={removeCheckoutLineItems}
          setLoading={setLoading}
          loading={loading}
        />
      </SideDrawer>
    )
  }

  return (
    <SideDrawer
      isOpen={isOpen}
      onClose={onClose}
      style={{ overscrollBehavior: "contain" }}
    >
      <Div h="100%" d="flex" flexDir="column">
        <Div
          d="flex"
          justify="space-between"
          align="center"
          m={{ b: "2.25rem" }}
          p={{ r: "1rem" }}
        >
          <Text
            textTransform="uppercase"
            textWeight="bold"
            fontFamily="secondary"
            textSize="display1"
          >
            cart
          </Text>
          <Div
            style={{ lineHeight: "0" }}
            bg="lightPink"
            shadow="2"
            hoverShadow="3"
            transition
            cursor="pointer"
            rounded="sm"
            onClick={() => onClose(false)}
          >
            <Image src="/close.svg" h="1rem" w="1rem" p="10px" />
          </Div>
        </Div>
        {loading ? (<Div
          flexGrow="1"
          overflow="auto"
          flexWrap="nowrap"
          p={{ b: { xs: "2.5rem", sm: "0rem" }, r: "1.5rem" }}
          m={{ r: "-1.5rem" }}
          justify="center"
          align="center"
        >
        <Div flexGrow="1 1 0%" m={{ t: "2rem" }} justify="center" align="center">
          <Icon
            name="Loading"
            transform="translateY(-50%)"
            size="25px"
            color="blue"
            style={{ zIndex: 1, opacity: 1}}
            m={{ r: "0.5rem" }}
          /></Div>
        </Div>):(
        <Div
          flexGrow="1"
          overflow="auto"
          flexWrap="nowrap"
          p={{ b: { xs: "2.5rem", sm: "0rem" }, r: "1.5rem" }}
          m={{ r: "-1.5rem" }}
        >
          {get(cart, "lineItems.edges", []).length === 0 && (
            <Div flexGrow="1 1 0%" m={{ t: "2rem" }}>
              <Text>Your Bag is Empty</Text>
            </Div>
          )}
          {get(cart, "lineItems.edges", []).map(edge => {
            return (
              <React.Fragment key={edge.node.id}>
                {/* {[1, 2, 3].map(id => {
            return ( */}
                <Div
                  p={{ y: "2.5rem" }}
                  m={{ r: "1rem" }}
                  border={{ t: "1px solid" }}
                  borderColor="yellowPink"
                  d="flex"
                  //key={id}
                >
                 <Link
                      to={`/products/${edge.node.variant.product.handle}/${edge.node.variant.title !== "Default Title" ? edge.node.variant.title.toLowerCase(): ''}`}
                      style={{ color: "inherit" }}
                      onClick={() => {
                          toggleCart({ variables: { cartDrawerVisible: false } })
                      }}
                    >
                  <Div
                    bgImg={get(edge, "node.variant.image.originalSrc")}
                    bgPos="center"
                    bgSize="cover"
                    rounded="10px"
                    m={{ r: "1.25rem" }}
                    h="4rem"
                    w="4rem"
                  /></Link>
                  <Div flexGrow="1">
                    <Div d="flex" justify="space-between" align="center">
                    <Link
                      to={`/products/${edge.node.variant.product.handle}/${edge.node.variant.title !== "Default Title" ? edge.node.variant.title.toLowerCase(): ''}`}
                      style={{ color: "inherit" }}
                      onClick={() => {
                          toggleCart({ variables: { cartDrawerVisible: false } })
                      }}
                    >
                      <Text textWeight="bold">{edge.node.title}</Text>
                      </Link>
                      <Div
                        style={{ lineHeight: "0" }}
                        bg="lightPink"
                        shadow="2"
                        cursor="pointer"
                        rounded="circle"
                        m={{ l: "20px" }}
                        onClick={() => onRemoveItem(edge.node)}
                      >
                        <Image src="/close.svg" h="10px" w="10px" p="5px" />
                      </Div>
                    </Div>
                    <Div>
                      {edge.node.variant.title !== "Default Title" && (
                        <Text m={{ t: "10px" }} textSize="paragraph1">
                          {edge.node.variant.title}
                        </Text>
                      )}
                      <Text m={{ t: "5px" }} textSize="paragraph1">
                        $
                        {(edge.node.variant.price * edge.node.quantity).toFixed(
                          2
                        )}
                      </Text>
                      {/* //Counter for products */}
                      <Div
                        d="flex"
                        rounded="lg"
                        m={{ t: "10px" }}
                        shadow="2"
                        maxW="4rem"
                        justify="space-between"
                      >
                        <Text
                          textWeight="bold"
                          p="2px 10px"
                          cursor="pointer"
                          onClick={() => onDecreaseQuantity(edge.node)}
                          // opacity={replaceCheckoutLineItemLoading ? "0.3" : "1"}
                        >
                          -
                        </Text>
                        <Text textWeight="bold" p="2px 0">
                          {/* {replaceCheckoutLineItemLoading ? (
                            <Image src="/loader.svg" />
                          ) : ( */}
                          {edge.node.quantity}
                          {/* )} */}
                        </Text>
                        <Text
                          textWeight="bold"
                          p="2px 10px"
                          cursor="pointer"
                          onClick={() => onIncreaseQuantity(edge.node)}
                          // opacity={replaceCheckoutLineItemLoading ? "0.3" : "1"}
                        >
                          +
                        </Text>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </React.Fragment>
            )
          })}
        </Div>
        )}
        {get(cart, "lineItems.edges", []).length > 0 && (
          <Div
            m={{ r: { sm: "1rem" } }}
            pos={{ xs: "fixed", sm: "static" }}
            left="1rem"
            right="1rem"
            bottom="2rem"
            w={{ sm: "100%" }}
          >
            <Div className="safe-margin">
              <BlueButton
                w="100%"
                text="Checkout"
                //padding="13px 74px"
                rounded="lg"
                onClick={() => {
                  setCheckingOutShopify(true)
                  window._learnq = window._learnq || []
                  window._learnq.push([
                    "track",
                    "Started Checkout",
                    {
                      $event_id: `${cart.id}_${Date.now()}`,
                      $value: cart.lineItemsSubtotalPrice.amount,
                      ItemNames: cart.lineItems.edges.map(
                        ({ node }) => node.title
                      ),
                      CheckoutURL: cart?.webUrl,
                      Items: cart.lineItems.edges.map(edge => {
                        const { node } = edge

                        return {
                          ProductID: atob(node.variant.id)
                            .split("/")
                            .slice(-1)[0],
                          SKU: node.variant.sku,
                          ProductName: node.title,
                          Quantity: node.quantity,
                          ItemPrice: node.variant.price,
                          RowTotal: node.variant.price * node.quantity,
                          ProductURL: `https://notpot.com/products/${node.variant.product.handle}`,
                          ImageURL: node.variant.image.originalSrc,
                        }
                      }),
                    },
                  ])

                  window.location.href = cart?.webUrl
                }}
                disabled={checkingOutShopify}
                prefix={
                  <Icon
                    name="Loading"
                    pos="absolute"
                    top="50%"
                    left="8.5%"
                    transform="translateY(-50%)"
                    size="25px"
                    color="white"
                    style={{ zIndex: 1, opacity: checkingOutShopify ? 1 : 0 }}
                    m={{ r: "0.5rem" }}
                  />
                }
              />
            </Div>
          </Div>
        )}
      </Div>
    </SideDrawer>
  )
}

export default CartModal
