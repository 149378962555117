import { gql } from "@apollo/client"

import { CHECKOUT_FIELDS, CUSTOMER_FIELDS } from "./fragments"

// @client
export const GET_RECHARGE_CHECKOUT = gql`
  {
    rechargeCheckout @client
  }
`

// @client
export const REGISTER_DRAWER_VISIBILITY = gql`
  {
    registerDrawerVisible @client
  }
`
// @client
export const TOGGLE_REGISTER_DRAWER = gql`
  mutation updateRegisterDrawerVisibility($registerDrawerVisible: Boolean!) {
    updateRegisterDrawerVisibility(
      registerDrawerVisible: $registerDrawerVisible
    ) @client
  }
`

// @client
export const LOGIN_DRAWER_VISIBILITY = gql`
  {
    loginDrawerVisible @client
  }
`

// @client
export const TOGGLE_LOGIN_DRAWER = gql`
  mutation updateLoginDrawerVisibility($loginDrawerVisible: Boolean!) {
    updateLoginDrawerVisibility(loginDrawerVisible: $loginDrawerVisible) @client
  }
`

export const FORGOT_PASSWORD_DRAWER_VISIBILITY = gql`
  {
    forgotPasswordDrawerVisible @client
  }
`

// @client
export const TOGGLE_FORGOT_PASSWORD_DRAWER = gql`
  mutation updateForgotPasswordVisibilityFilter(
    $forgotPasswordDrawerVisible: Boolean!
  ) {
    updateForgotPasswordDrawerVisibility(
      forgotPasswordDrawerVisible: $forgotPasswordDrawerVisible
    ) @client
  }
`

// @client
export const CART_VISIBILITY = gql`
  {
    cartDrawerVisible @client
  }
`

// @client
export const RECHARGE_CART_VISIBILITY = gql`
  {
    rechargeCartDrawerVisible @client
  }
`

// @client
export const TOGGLE_CART = gql`
  mutation updateCartVisibilityFilter($cartDrawerVisible: Boolean!) {
    updateCartVisibilityFilter(cartDrawerVisible: $cartDrawerVisible) @client
  }
`

export const TOGGLE_RECHARGE_CART = gql`
  mutation updateRechargeCartVisibilityFilter(
    $rechargeCartDrawerVisible: Boolean!
  ) {
    updateRechargeCartVisibilityFilter(
      rechargeCartDrawerVisible: $rechargeCartDrawerVisible
    ) @client
  }
`

// @client
export const UPDATE_RECHARGE_CHECKOUT = gql`
  mutation updateRechargeCheckout($checkout: JSON!) {
    updateRechargeCheckout(checkout: $checkout) @client
  }
`

// @shopify
export const GET_PRODUCTS_BY_IDS = gql`
  query getProductById($ids: [ID!]!) {
    nodes(ids: $ids) {
      id
      ... on ProductVariant {
        quantityAvailable
        title
        product {
          title
        }
      }
    }
  }
`

// @shopify
export const CHECKOUT_CREATE = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        ...checkoutFields
      }
      checkoutUserErrors {
        code
        message
      }
    }
  }

  ${CHECKOUT_FIELDS}
`

// @shopify
export const CHECKOUT_LINE_ITEMS_ADD = gql`
  mutation checkoutLineItemsAdd(
    $items: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsAdd(lineItems: $items, checkoutId: $checkoutId) {
      checkout {
        ...checkoutFields
      }
    }
  }

  ${CHECKOUT_FIELDS}
`

// @shopify
export const CHECKOUT_LINE_ITEMS_REPLACE = gql`
  mutation checkoutLineItemsReplace(
    $items: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsReplace(lineItems: $items, checkoutId: $checkoutId) {
      checkout {
        ...checkoutFields
      }
    }
  }

  ${CHECKOUT_FIELDS}
`

// @shopify
export const CHECKOUT_LINE_ITEMS_REMOVE = gql`
  mutation checkoutLineItemsRemove($lineItemIds: [ID!]!, $checkoutId: ID!) {
    checkoutLineItemsRemove(
      lineItemIds: $lineItemIds
      checkoutId: $checkoutId
    ) {
      checkout {
        ...checkoutFields
      }
    }
  }

  ${CHECKOUT_FIELDS}
`
// @shopify
export const CHECKOUT_BY_ID = gql`
  query($id: ID!) {
    node(id: $id) {
      ...checkoutFields
    }
  }

  ${CHECKOUT_FIELDS}
`

// @shopify
export const CREATE_CUSTOMER_TOKEN = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      userErrors {
        field
        message
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }
`

/**
 * fetches customer profile
 *
 * @param {*} accessToken
 * @context shopify
 */
export const FETCH_CUSTOMER = gql`
  query($accessToken: String!) {
    customer(customerAccessToken: $accessToken) {
      ...customerFields
    }
  }

  ${CHECKOUT_FIELDS}
  ${CUSTOMER_FIELDS}
`

/**
 * register query
 *
 * @param {*} inputs
 * @context shopify
 */
export const REGISTER_CUSTOMER = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      userErrors {
        field
        message
      }
      customer {
        id
      }
    }
  }
`

/**
 * query for sending token for forgot password
 *
 * @param {*} inputs
 */
export const CUSTOMER_RECOVER = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      userErrors {
        field
        message
      }
    }
  }
`

/**
 * query for resetting password
 *
 * @param {*} inputs
 */
export const CUSTOMER_RESET = gql`
  mutation customerReset($id: ID!, $input: CustomerResetInput!) {
    customerReset(id: $id, input: $input) {
      userErrors {
        field
        message
      }
      customer {
        id
      }
    }
  }
`
/**
 * query for updating profile
 *
 * @param {*} inputs
 */
export const CUSTOMER_UPDATE = gql`
  mutation customerUpdate(
    $customerAccessToken: String!
    $customer: CustomerUpdateInput!
  ) {
    customerUpdate(
      customerAccessToken: $customerAccessToken
      customer: $customer
    ) {
      customerUserErrors {
        field
        message
      }
      customer {
        ...customerFields
      }
    }
  }
  ${CHECKOUT_FIELDS}
  ${CUSTOMER_FIELDS}
`

/**
 * query for updating password
 *
 * @param {*} inputs
 */
export const CUSTOMER_UPDATE_PASSWORD = gql`
  mutation customerUpdate(
    $customerAccessToken: String!
    $customer: CustomerUpdateInput!
  ) {
    customerUpdate(
      customerAccessToken: $customerAccessToken
      customer: $customer
    ) {
      customerUserErrors {
        field
        message
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }
`

export const CUSTOMER_ORDERS = gql`
  query($accessToken: String!) {
    customer(customerAccessToken: $accessToken) {
      orders(first: 100, sortKey: PROCESSED_AT, reverse: true) {
        edges {
          node {
            processedAt
            orderNumber
            statusUrl
            totalPriceV2 {
              amount
            }
            lineItems(first: 100) {
              edges {
                node {
                  title
                  quantity
                  variant {
                    image {
                      originalSrc
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * query for Activate Customer
 *
 * @param {*} inputs
 */

export const CUSTOMER_ACTIVATE = gql`
  mutation customerActivate($id: ID!, $input: CustomerActivateInput!) {
    customerActivate(id: $id, input: $input) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

